<template>
    <!-- ContentText-->
    <div :class="content.data.style">

    <div
        :class="'m-2 md:m-4 min-h-5 '+  (editable ? 'cursor-pointer striped': '') +   (content.edit_mode && editable ? 'striped_no_hover': '')  "
        @click.passive="content.edit_mode=true"
        v-html="content.data.text">

    </div>
    </div>



</template>

<script>

export default {
    emits: ["clicked"], //
    name: "ContentText",
    components: {


    },
    props: {
        content: Object,
        contents: Object,
        pages: Object,
        editable:Boolean
    },
    data() {
        return {

        }
    },
    methods: {

    },
}
</script>

